import * as client_hooks from '../../../src/hooks.client/index.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/about": [12],
		"/(legal)/acceptable-use": [5,[2]],
		"/cart": [13],
		"/(legal)/cookie-policy": [6,[2]],
		"/(legal)/disclaimer": [7,[2]],
		"/(legal)/dmca-policy": [8,[2]],
		"/folder/[...path]": [14,[3]],
		"/order/[id]": [~15],
		"/(legal)/privacy-policy": [9,[2]],
		"/(legal)/refund-policy": [10,[2]],
		"/search": [~16],
		"/(legal)/terms-of-use": [11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';